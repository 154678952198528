import instagram from '../assets/under_construction/instagram.svg';
import twitter from '../assets/under_construction/twitter.svg';
import youtube from '../assets/under_construction/youtube.svg';

const socialMedia = [
  {
    icon: instagram,
    customClass: 'UnderConstruction__network__instagram',
    alt: 'Colegio Romera Instagram',
    url: 'https://www.instagram.com/colegioromera/?hl=es-la',
  },
  {
    icon: twitter,
    customClass: 'UnderConstruction__network__twitter',
    alt: 'Colegio Romera Twitter',
    url: 'https://twitter.com/colegioromera',
  },
  {
    icon: youtube,
    customClass: 'UnderConstruction__network__youtube',
    alt: 'Colegio Romera YouTube',
    url: 'https://www.youtube.com/user/ColegioRomera',
  },
];

export default socialMedia;
