import React from 'react';

import Social from './Social';

import logo from '../assets/under_construction/romera_logo.svg';
import caret from '../assets/under_construction/caret_down.svg';
import socialMedia from '../data/socialMedia';

import '../styles/under_construction.scss';

const UnderConstruction = () => {
  const handleScrollDown = () => {
    const contact = document.querySelector('#Contact');
    const contactOffsetTop = contact.offsetTop;
    window.scrollTo({
      top: contactOffsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className='UnderConstruction'>
      <div className='Wrapper'>
        <div className='UnderConstruction__logo'>
          <img src={logo} alt='Colegio Romera Logo' />
        </div>
        <div className='UnderConstruction__info'>
          <h1 className='UnderConstruction__title'>Nos estamos renovando</h1>
          <p className='UnderConstruction__text'>
            Nuestro sitio Web está en construcción, ¡pero estamos listos para
            comenzar!
          </p>
        </div>
        <div className='UnderConstruction__social'>
          {socialMedia.map(({ icon, customClass, alt, url }, key) => (
            <Social
              icon={icon}
              customClass={customClass}
              alt={alt}
              url={url}
              key={key}
            />
          ))}
        </div>
        <div className='UnderConstruction__down' onClick={handleScrollDown}>
          <img src={caret} alt='Scroll Down' />
        </div>
      </div>
    </section>
  );
};

export default UnderConstruction;
