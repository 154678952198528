const sendEmail = async data => {
  const init = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await fetch('/sendEmail.php', init);
  return response.json();
};

export default sendEmail;
