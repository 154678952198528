import React, { useState } from 'react';
import sendEmail from '../helpers/sendEmail';

import '../styles/contact.scss';

const Contact = () => {
  const [name, setName] = useState({
    value: '',
    error: false,
  });
  const [email, setEmail] = useState({
    value: '',
    error: false,
  });
  const [level, setLevel] = useState('primaria');
  const [tel, setTel] = useState({
    value: '',
    error: false,
  });
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState({});

  const isDisabled =
    name.error ||
    email.error ||
    tel.error ||
    !name.value ||
    !email.value ||
    !tel.value;

  const handleFormUpdate = e => {
    const { value, name } = e.target;

    switch (name) {
      case 'name':
        setName(prevState => ({
          ...prevState,
          error: !value,
          value: !value ? '' : value,
        }));
        return;

      case 'email':
        setEmail(prevState => ({
          ...prevState,
          error: !value,
          value: !value ? '' : value,
        }));
        return;

      case 'level':
        setLevel(value);
        return;

      case 'tel':
        setTel(prevState => ({
          ...prevState,
          error: !value,
          value: !value ? '' : value,
        }));
        return;

      case 'message':
        setMessage(value);
        return;
    }
  };

  const handleFormSubmit = e => {
    const initialValues = { error: false, value: '' };

    const data = {
      name: name.value,
      email: email.value,
      level,
      tel: tel.value,
      message,
    };

    e.preventDefault();
    if (isDisabled) return;

    setName(initialValues);
    setEmail(initialValues);
    setLevel('primaria');
    setTel(initialValues);
    setMessage('');

    sendEmail(data).then(res => setResponse(res));
  };

  return (
    <section className='Contact' id='Contact'>
      <div className='Wrapper'>
        <h2 className='Contact__title'>Contacto</h2>
        <form className='Contact__form' onSubmit={handleFormSubmit}>
          <input
            className={`Contact__formInput ${
              name.error ? 'Contact__formInput-error' : ''
            }`}
            placeholder='Nombre Completo *'
            value={name.value}
            name='name'
            onChange={handleFormUpdate}
          />
          <input
            className={`Contact__formInput ${
              email.error ? 'Contact__formInput-error' : ''
            }`}
            placeholder='Correo *'
            value={email.value}
            name='email'
            onChange={handleFormUpdate}
          />
          <select
            className='Contact__formInput'
            value={level}
            name='level'
            onChange={handleFormUpdate}
          >
            <option value='primaria'>Primaria</option>
            <option value='secundaria'>Secundaria</option>
            <option value='preparatoria'>Preparatoria</option>
          </select>
          <input
            className={`Contact__formInput ${
              tel.error ? 'Contact__formInput-error' : ''
            }`}
            placeholder='Teléfono *'
            value={tel.value}
            name='tel'
            onChange={handleFormUpdate}
          />
          <textarea
            className='Contact__formTextarea'
            placeholder='Mensaje'
            value={message}
            name='message'
            onChange={handleFormUpdate}
          />
          <button className='Contact__button' disabled={isDisabled}>
            Enviar
          </button>
        </form>
        {response.message && (
          <p
            className={`Contact__response ${
              response.error ? 'Contact__response-error' : ''
            }`}
          >
            {response.message}
          </p>
        )}
      </div>
    </section>
  );
};

export default Contact;
