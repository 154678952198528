import React from 'react';

import Social from './Social';

import '../styles/footer.scss';

import socialMedia from '../data/socialMedia';

import logo from '../assets/footer/romera-logo-white.svg';

const Footer = () => {
  return (
    <footer className='Footer'>
      <div className='Wrapper'>
        <div className='Footer--logo'>
          <img src={logo} alt='Colegio Romera Logo' />
        </div>
        <div className='Footer--info'>
          <div className='Footer--social'>
            {socialMedia.map(({ icon, customClass, alt, url }, key) => (
              <Social
                icon={icon}
                customClass={customClass}
                alt={alt}
                url={url}
                key={key}
              />
            ))}
          </div>
          <div className='Footer--phone'>
            <p className='Footer--phoneNumber'>
              <a href='tel:+525540890701'>55 4089 0701</a>
            </p>
          </div>
          <div className='Footer--address'>
            <p className='Footer--addressInfo'>
              Dirección: Homero 707, Polanco, C.P. 11560, México, Ciudad de
              México
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
