import React from 'react';

import UnderConstruction from './UnderConstruction';
import Levels from './Levels';
import Contact from './Contact';
import Footer from './Footer';

const App = () => (
  <section className='App'>
    <UnderConstruction />
    <Contact />
    <Levels />
    <Footer />
  </section>
);

export default App;
