import React from 'react';
import PropTypes from 'prop-types';

import '../styles/social.scss';

const Social = ({ icon, customClass, alt, url }) => {
  return (
    <a href={url} className={`Social ${customClass}`} target='_blank'>
      <img src={icon} alt={alt} />
    </a>
  );
};

Social.propTypes = {
  icon: PropTypes.string.isRequired,
  customClass: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Social;
