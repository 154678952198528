import React from 'react';
import PropTypes from 'prop-types';

import '../styles/button.scss';

const Button = ({ url, text = 'Conoce Más' }) => {
  return (
    <a className='Button' href={url} target='_blank'>
      {text}
    </a>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default Button;
