import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

import '../styles/level.scss';

const Level = ({ title, text = 'Conoce Más', url, bgColor, reverse }) => {
  return (
    <div
      className={`Level Level--${title} ${reverse ? `Level--reverse` : ``}`}
      style={{ background: `${bgColor}` }}
    >
      <div className='Level__left'>
        <div className='Level__header'>
          <h2 className='Level__title'>{title}</h2>
          <span className='Level__title__highlight' />
        </div>
        <p className='Level__info'>{text}</p>
        <Button url={url} />
      </div>
      <div className='Level__right' />
    </div>
  );
};

Level.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

export default Level;
