import React from 'react';

import Level from './Level';

import '../styles/levels.scss';

const levels = [
  {
    title: 'Primaria',
    text:
      'En un ambiente que promueve la felicidad y la autoestima, los niños de la primaria del Colegio Romera, se desenvuelven en óptimas condiciones para lograr los puntajes que demuestran su alto nivel académico.',
    url: 'http://romera.edu.mx/landingprimaria/',
    bgColor: '#FFC93C',
  },
  {
    title: 'Secundaria',
    text:
      'En lo académico, el Colegio propone para los jóvenes de secundaria, una educación analítica, crítica y creadora.',
    url: 'http://romera.edu.mx/landingsecundaria/',
    bgColor: '#1D2646',
    reverse: true,
  },
  {
    title: 'Preparatoria',
    text:
      'La preparatoria del Colegio Romera, está incorporada a la Secretaría de Educación Pública bajo la modalidad de “Bachillerato Técnico en Administración de Empresas” y tiene una duración de seis semestres.',
    url: 'http://romera.edu.mx/landingpreparatoria/',
    bgColor: '#FA163F',
  },
];

const Levels = () => {
  return (
    <section className='Levels'>
      <h2 className='Levels__title'>Niveles Educativos</h2>
      <div className='Levels__container'>
        {levels.map(({ title, text, url, bgColor, reverse }, key) => (
          <Level
            bgColor={bgColor}
            text={text}
            title={title}
            url={url}
            key={key}
            reverse={reverse}
          />
        ))}
      </div>
    </section>
  );
};

export default Levels;
